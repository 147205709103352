import React, { useEffect } from "react"
// import PropTypes from 'prop-types'
// import styled from 'styled-components'
import { graphql } from "gatsby"
import Layout from "components/Layout"
import SEO from "components/SEO"
import SliceZone from "components/SliceZone"
import { scrollElementIntoView } from "utils/scrollIntoView"

function Index({ data: { prismic, site }, ...props }) {
  const { page, site_settings } = prismic

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (window.location.hash !== "") {
        setTimeout(() => {
          scrollElementIntoView(null, window.location.hash)
        }, 200)
      }
    }
  })

  if (!page) return null
  const { body } = page
  const {
    meta_title,
    meta_description,
    meta_images,
    _meta: { lang },
  } = page
  const { siteUrl, ...defaultMeta } = site.defaultMeta
  const uri = "/"

  const onPageNav = body
    .map((s) => {
      if (s.primary?.anchor_title) {
        return {
          anchor: s.primary.anchor_title,
        }
      }
      return null
    })
    .filter(Boolean)

  return (
    <>
      <SEO
        lang={lang}
        slug={uri}
        title={meta_title}
        description={meta_description}
        images={meta_images}
        defaultMeta={defaultMeta}
      />
      <Layout site_settings={site_settings} lang={lang} pageNav={onPageNav}>
        <SliceZone
          slices={body}
          pageInfo={{ uri, host: siteUrl, title: page.title[0].text }}
        />
      </Layout>
    </>
  )
}

export default Index

Index.defaultProps = {}

Index.propTypes = {}

export const query = graphql`
  query IndexPage($isProduction: Boolean!) {
    site {
      defaultMeta: siteMetadata {
        ...SiteMetaFragment
      }
    }
    prismic {
      site_settings(lang: "da-dk", uid: "site_settings") {
        copyright_information
      }
      page(lang: "da-dk", uid: "wellness") {
        _meta {
          type
          lang
          uid
        }
        title
        meta_title
        meta_description
        meta_images {
          image
          imageSharp @include(if: $isProduction) {
            childImageSharp {
              id
              main: fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        show_in_sitemap
        body {
          ... on PRISMIC_PageBodyCustom_hero_section {
            type
            primary {
              image
              imageSharp @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fixed(width: 900, height: 798) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  mobile: fixed(width: 600, height: 526) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
          ... on PRISMIC_PageBodyCustom_products {
            type
            fields {
              product_key
              image
              imageSharp @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fixed(width: 1048, height: 210) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  mobile: fixed(width: 480, height: 200) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
          ... on PRISMIC_PageBodyInstagram_feed {
            type
            primary {
              profile_photo
              imageSharp: profile_photoSharp @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fixed(width: 169) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  mobile: fixed(width: 169) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
              instagram_handle
              cta_text
              cta_button_text
              cta_link {
                _linkType
                ... on PRISMIC__ExternalLink {
                  url
                  target
                }
              }
            }
            fields {
              story
              imageSharp: storySharp @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fixed(width: 475) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  mobile: fixed(width: 238) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
          ... on PRISMIC_PageBodyParagraph___image {
            type
            primary {
              use_anchor
              anchor_title
              image_position
              content
              content_image_positions
              paragraph_image
              imageSharp: paragraph_imageSharp @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  mobile: fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on PRISMIC_PageBodyHero_section {
            type
            label
            primary {
              hero_image
              imageSharp: hero_imageSharp @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fixed(width: 1920, height: 1080) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  mobile: fixed(width: 480, height: 660) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
              mobile_grid
              tablet_portrait_grid
              tablet_landscape_grid
              graphics_container
              align_content
            }
            fields {
              graphic
              grid_position
              justify_graphic
              align_graphic
              sizes
            }
          }
          ... on PRISMIC_PageBodyFeature_repeater {
            type
            label
            primary {
              use_anchor
              anchor_title
              features_content
              features_title
              features_content_after
            }
            fields {
              feature_description
              feature_icon
            }
          }
          ... on PRISMIC_PageBodyParallax_background {
            type
            primary {
              visible_height
              image
              imageSharp: imageSharp @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fixed(width: 1920, height: 1080) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  mobile: fixed(width: 480) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
          ... on PRISMIC_PageBodyProduct_repeater {
            type
            label
            primary {
              theme_color
              use_anchor
              anchor_title
              product_columns
              products_title
              products_content
              product_catalogue_image
              imageSharp: product_catalogue_imageSharp
                @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fixed(width: 1048, height: 210) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  mobile: fixed(width: 480, height: 200) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
              product_catalogue_icon
            }
            fields {
              product_icon
              product {
                ... on PRISMIC_Product {
                  ...ProductFragment
                }
              }
            }
          }
          ... on PRISMIC_PageBodyVideo_repeater {
            type
            label
            primary {
              use_anchor
              anchor_title
              video_columns
              videos_title
              videos_content
            }
            fields {
              youtube_url
              video_description
            }
          }
        }
      }
    }
  }
`
